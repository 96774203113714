import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import {
  addErrorNotification,
  removeNotification as removeNotificationAction,
} from '../../store/SystemNotifications/SystemNotificationsActions'

import { getNotifications } from '../../store/SystemNotifications/SystemNotificationsReducer'

import SystemNotifications from './components/system-notifications'

const mapStateToProps = state => ({
  notifications: getNotifications(state),
})

const mapDispatchToProps = dispatch => ({
  addNew: () => {
    dispatch(addErrorNotification('yoyo'))
  },
  removeNotification: id => {
    dispatch(removeNotificationAction(id))
  },
})

const SystemNotificationsContainer = ({ notifications, removeNotification }) => (
  <SystemNotifications notifications={notifications} onRemoveNotification={removeNotification} />
)

SystemNotificationsContainer.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.any).isRequired,
  removeNotification: PropTypes.func.isRequired,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SystemNotificationsContainer)
