import React from 'react'

import SVGWrapper from './svg-wrapper'

const ArrowRightIcon = props => (
  <SVGWrapper width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
    <path d="M0-.25h24v24H0z" fill="none" />
  </SVGWrapper>
)

export default ArrowRightIcon
