import React from 'react'
import PropTypes from 'prop-types'

const SVGWrapper = ({ children, height, width, ...props }) => {
  const defaultSize = 24
  return (
    <svg
      fill="currentColor"
      preserveAspectRatio="xMidYMid meet"
      height={height || defaultSize}
      width={width || defaultSize}
      {...props}
    >
      {children}
    </svg>
  )
}

SVGWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

SVGWrapper.defaultProps = {
  width: null,
  height: null,
}

export default SVGWrapper
