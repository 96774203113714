/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Label from './label'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const Container = styled.div`
  max-width: 450px;
  min-width: 200px;
  position: relative;
  width: 100%;
  text-align: left;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      max-width: 100%;
    `};
`

const Element = styled.textarea`
  appearance: none;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  box-sizing: border-box;
  color: #565a5c;
  cursor: text;
  display: block;
  font-size: 16px;
  font-weight: 300;
  height: 100%;
  left: 0;
  line-height: 20px;
  margin: 0;
  min-height: 60px;
  overflow: auto;
  padding: 8px 10px;
  position: absolute;
  resize: none;
  top: 0;
  transition: border-color 0.2s;
  white-space: pre-wrap;
  width: 100%;
  word-wrap: break-word;
  z-index: 2;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      display: block;
      width: 100%;
    `};

  ${({ isError }) =>
    isError &&
    css`
      border: 1px solid ${gigplanetTheme.color.inputError};
    `};
`

const Wrapper = styled.div`
  position: relative;
  min-height: 60px;
  width: 100%;

  pre {
    display: block;
    position: relative;
    z-index: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    min-height: 60px;
    margin: 0;
    padding: 8px 10px;
    border-radius: 2px;
    outline: 0;
    border: 0;
    width: 100%;
    vertical-align: top;
    white-space: pre-wrap;
    word-wrap: break-word;
    visibility: hidden;
    box-sizing: border-box;
  }
`

const HelpTextElement = styled.small`
  font-size: 12px;
  line-height: 15px;
  color: #82888a;
`

const ErrorElement = styled.small`
  color: ${gigplanetTheme.color.inputError};
  font-size: 12px;
  line-height: 15px;
`

const LimitElement = styled.div`
  position: absolute;
  color: #82888a;
  right: 0;
  top: 0;
  font-size: 14px;
  padding: 0 0 5px;
  line-height: 20px;
  font-weight: 600;
  display: block;
`

const Textarea = ({
  label,
  helpText,
  placeholder,
  id,
  name,
  value,
  errorMessage,
  onChange,
  onBlur,
  onKeyDown,
  labelPositioning,
  maxLength,
  expanded,
  disabled,
  required,
  autoFocus,
  labelHelp,
}) => {
  let limitElement = null
  if (maxLength && maxLength > 0) {
    const desc = value && value.length > 0 ? 'tegn igjen' : 'tegn'
    limitElement = <LimitElement>{`${maxLength - value.length} ${desc}`}</LimitElement>
  }
  const maxLengthValue = maxLength || ''
  const textarea = (
    <Element
      id={id}
      name={name}
      value={value}
      maxLength={maxLengthValue}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      autoFocus={autoFocus}
      isExpanded={expanded}
      isError={!!errorMessage}
    />
  )
  const tmpLabel = (
    <Label htmlFor={id} position={labelPositioning} labelHelp={labelHelp}>
      {label}
    </Label>
  )
  const tmpHelpText = <HelpTextElement>{helpText}</HelpTextElement>
  const tmpErrorMessage = <ErrorElement>{errorMessage}</ErrorElement>

  let helpTextElement = null
  if (errorMessage) {
    helpTextElement = tmpErrorMessage
  } else if (helpText) {
    helpTextElement = tmpHelpText
  }

  const labelElement = label ? tmpLabel : null

  return (
    <Container isExpanded={expanded}>
      {labelElement}
      {limitElement}
      <Wrapper>
        <pre>
          <span>{value}</span>
          <br />
        </pre>
        {textarea}
      </Wrapper>
      {helpTextElement}
    </Container>
  )
}

Textarea.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  labelPositioning: PropTypes.string,
  maxLength: PropTypes.number,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  labelHelp: PropTypes.string,
}

Textarea.defaultProps = {
  autoFocus: false,
  labelPositioning: '',
  value: '',
  errorMessage: '',
  labelHelp: '',
  onChange: null,
  onBlur: null,
  onKeyDown: null,
  expanded: false,
  disabled: false,
  required: false,
  label: '',
  helpText: '',
  placeholder: '',
}

export default Textarea
