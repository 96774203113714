import styled from 'styled-components'

import gigplanetTheme from '../../styles/gigplanetTheme'

export const StyledNoAccess = styled.div`
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;
`

export const Link = styled.a`
  color: ${gigplanetTheme.color.primary};
  text-decoration: none;
  border: 1px solid ${gigplanetTheme.color.primary};
  border-radius: 3px;
  padding: 10px 30px;
  line-height: 20px;

  &:hover {
    text-decoration: none;
  }
`

export const Text = styled.p`
  color: RED;
  margin-bottom: 50px;
`
