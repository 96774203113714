const gigplanetTheme = {
  color: {
    primaryBackground: '#fff',
    secondaryBackground: '#f4f4f4',
    primary: '#8805BB',
    primaryText: 'black',
    border: '#e3e5e8',
    tableBorder: '#e3e5e8',
    tableHead: '#565a5c',
    tableCell: '#484848',
    tableUser: '#e3e5e8',
    tableMenu: '#565a5c',
    inputBorder: '#c4c4c4',
    inputActiveBorder: '#8805BB',
    inputBackground: '#fff',
    inputDisabledBackground: '#edefed',
    inputText: '#484848',
    inputDisabledText: '#bbb',
    inputError: '#ff5a5f',
    inputValidated: 'green',
    labelText: '#484848',
    labelDisabledText: '#bbb',
    inputHelpText: '#82888a',
    inputLimit: '#82888a',
    ratingStarEmpty: '#dce0e0',
    ratingStarFilled: '#F68B1F',
  },
  measure: {
    maxWidth: '1240px',
  },
  spacing: {
    small: '16px',
    medium: '32px',
    large: '48px',
    xlarge: '64px',
  },
  zIndex: {
    header: 100,
    controlpanelMenu: 99,
    popup: 500,
    formElements: 100,
    profileMenu: 200,
    favoriteMenu: 200,
    artistSelection: 200,
    progressbar: 600,
    modalWindow: 700,
    submitting: 990,
    dropdown: 650,
    dropdownContent: 750,
    audioplayer: 10,
    requestGigrequest: 9,
    imageGallery: 900,
  },
}
const { color } = gigplanetTheme
export { color }

export default gigplanetTheme
