import React from 'react'
import PropTypes from 'prop-types'

import useCurrentUser from '../../hooks/useCurrentUser'
import GlobalStyle from '../global-style'

import { StyledMainLayout, MenuWrapper, ContentWrapper, NoAccessWrapper } from './style'
import NoAccess from '../no-access'
import SystemNotificationsContainer from '../../containers/system-notifications'
import MainMenu from '../main-menu'
import { ROLE } from '../../models/User'

const MainLayout = ({ children }) => {
  const currentUser = useCurrentUser()
  if (!{}.hasOwnProperty.call(currentUser, 'id')) {
    return (
      <NoAccessWrapper>
        <NoAccess />
        <GlobalStyle />
      </NoAccessWrapper>
    )
  }

  if (currentUser.role !== ROLE.ADMIN) {
    return (
      <NoAccessWrapper>
        <NoAccess hideLogin />
        <GlobalStyle />
      </NoAccessWrapper>
    )
  }

  return (
    <StyledMainLayout>
      <SystemNotificationsContainer />
      <MenuWrapper>
        <MainMenu />
      </MenuWrapper>
      <ContentWrapper>{children}</ContentWrapper>
      <GlobalStyle />
    </StyledMainLayout>
  )
}

MainLayout.propTypes = {
  children: PropTypes.element,
}

MainLayout.defaultProps = {
  children: null,
}

export default MainLayout
