import React from 'react'
import PropTypes from 'prop-types'

import { StyledNoAccess, Link, Text } from './style'
import getGlobals from '../../helpers/get-globals'

const NoAccess = ({ hideLogin }) => (
  <StyledNoAccess>
    <Text>Ingen tilgang</Text>
    {!hideLogin ? <Link href={`${getGlobals().FRONTEND_SERVER_HOST}/login`}>Logg inn</Link> : null}
  </StyledNoAccess>
)

NoAccess.propTypes = {
  hideLogin: PropTypes.bool,
}

NoAccess.defaultProps = {
  hideLogin: false,
}
export default NoAccess
