import React from 'react'
import PropTypes from 'prop-types'

import { StyledAdminContent, Content } from './style'
import AdminSidebar from '../admin-sidebar'

const AdminContent = ({ sidebar, children }) => (
  <StyledAdminContent>
    <AdminSidebar menuItems={sidebar} />
    <Content>{children}</Content>
  </StyledAdminContent>
)

AdminContent.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      as: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
}

AdminContent.defaultProps = {
  sidebar: [],
}

export default AdminContent
