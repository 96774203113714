import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../button'

const ModalWindowButton = ({ theme, id, isOpen, onClick, children, ...rest }) => (
  <Button
    {...rest}
    type="button"
    theme={theme}
    aria-haspopup
    aria-controls={id}
    aria-expanded={isOpen}
    onClick={e => {
      e.preventDefault()
      onClick()
    }}
  >
    {children}
  </Button>
)

ModalWindowButton.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  theme: PropTypes.string,
  children: PropTypes.node,
}

ModalWindowButton.defaultProps = {
  theme: 'button',
  children: null,
}

export default ModalWindowButton
