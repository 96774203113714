import React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import Link from 'next/link'

import { StyledAdminSidebar, DesktopMenu, MobileMenu, MenuItem, MenuLink } from './style'
import { Select } from '../../ui/form'

const AdminSidebar = ({ menuItems }) => {
  const router = useRouter()
  const { asPath, push } = router
  if (!menuItems || menuItems.length === 0) return null

  const desktopItems = []
  const mobileItems = []
  menuItems.forEach(item => {
    desktopItems.push(
      <MenuItem key={`desktop-item-${item.key}`}>
        <Link href={item.href} as={item.as} passHref>
          <MenuLink isActive={item.as === asPath}>{item.text}</MenuLink>
        </Link>
      </MenuItem>,
    )

    mobileItems.push(
      <option key={`mobile-item-${item.key}`} value={item.as}>
        {item.text}
      </option>,
    )
  })

  return (
    <StyledAdminSidebar>
      <DesktopMenu>{desktopItems}</DesktopMenu>
      <MobileMenu>
        <Select
          id="mobile-menu"
          value={asPath}
          onChange={e => {
            push(e.currentTarget.value)
          }}
          expanded
        >
          {mobileItems}
        </Select>
      </MobileMenu>
    </StyledAdminSidebar>
  )
}

AdminSidebar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      as: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
}

AdminSidebar.defaultProps = {
  menuItems: [],
}

export default AdminSidebar
