import React from 'react'
import PropTypes from 'prop-types'

import { StyledSystemNotifications } from './style'
import Item from './item'

const SystemNotifications = ({ notifications, onRemoveNotification }) => (
  <StyledSystemNotifications>
    {notifications.map(notification => (
      <Item
        key={notification.id}
        id={notification.id}
        type={notification.type}
        message={notification.message}
        onRemove={onRemoveNotification}
      />
    ))}
  </StyledSystemNotifications>
)

SystemNotifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.any).isRequired,
  onRemoveNotification: PropTypes.func.isRequired,
}

export default SystemNotifications
