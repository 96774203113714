import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import gigplanetTheme from '../../../styles/gigplanetTheme'

import Label from './label'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
`

const Element = styled.input`
  appearance: none;
  background-color: ${gigplanetTheme.color.inputBackground};
  border: 1px solid ${gigplanetTheme.color.inputBorder};
  border-radius: 2px;
  color: ${gigplanetTheme.color.primary};
  cursor: pointer;
  height: 18px;
  margin-right: 5px;
  margin-top: 4px;
  position: relative;
  transition: border-color 0.2s;
  vertical-align: top;
  width: 18px;
  min-width: 18px;

  &:checked {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24'><path d='M0 0h24v24H0z' fill='none' /><path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' style='fill: #8805bb' /></svg>");
    background-origin: content-box;
    background-position: -1px 0;
    background-repeat: no-repeat;
  }

  ${({ isNoLabel }) =>
    isNoLabel &&
    css`
      margin: 0;
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: default;
    `};

  ${({ isNoBorder }) =>
    isNoBorder &&
    css`
      border: 1px solid transparent;
    `};
`

const LabelContainer = styled.div``

const HelpTextElement = styled.span`
  color: #82888a;
  display: block;
  font-size: 12px;
`

const Checkbox = ({
  smallText,
  label,
  disabled,
  hideBorder,
  id,
  value,
  checked,
  onChange,
  helpText,
  lineThrough,
}) => (
  <Container>
    <Element
      type="checkbox"
      id={id}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      isNoLabel={!label}
      isDisabled={!disabled}
      isNoBorder={hideBorder}
    />
    <LabelContainer>
      {label && (
        <Label htmlFor={id} helpText={helpText} radio lineThrough={lineThrough} disabled={disabled}>
          {label}
        </Label>
      )}
      {helpText && <HelpTextElement>{helpText}</HelpTextElement>}
    </LabelContainer>
  </Container>
)

Checkbox.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  smallText: PropTypes.bool,
  lineThrough: PropTypes.bool,
  hideBorder: PropTypes.bool,
  helpText: PropTypes.string,
}

Checkbox.defaultProps = {
  label: '',
  value: '',
  onChange: null,
  checked: false,
  disabled: false,
  smallText: false,
  helpText: '',
  lineThrough: false,
  hideBorder: false,
}

export default Checkbox
