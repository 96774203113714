/* globals document */
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import createFocusTrap from 'focus-trap'
import styled from 'styled-components'

import mediaQueries from '../../../styles/mediaQueries'
import gigplanetTheme from '../../../styles/gigplanetTheme'

import { CloseIcon } from '../../../icons'

import noScroll from '../../../helpers/no-scroll'

const Backdrop = styled.div`
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${gigplanetTheme.zIndex.modalWindow};
`

const Content = styled.div`
  background-color: #fff;
  border-radius: 2px;
  color: ${gigplanetTheme.color.primaryText};
  cursor: default;
  left: 50%;
  margin: auto;
  max-height: 95%;
  max-width: 100%;
  min-height: 50px;
  min-width: 100px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.5s ease-in-out;

  ${mediaQueries.mobile`
    min-width: 300px;
  `};

  &:focus {
    outline: 0;
  }
`

const CloseButton = styled.button`
  background: transparent;
  border: 0;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  svg {
    color: ${gigplanetTheme.color.primaryText};
  }
`

const animationLeaveDuration = 500

class ModalWindowContent extends React.Component {
  constructor(props) {
    super(props)
    this.focusTrap = null
    this.modalWindowRef = React.createRef()
    this.modalWindowContentRef = React.createRef()
    this.modalRoot = null
    this.el = null

    if (typeof document !== 'undefined') {
      this.modalRoot = document.getElementById('modal')
      this.el = document.createElement('div')
    }
  }

  componentDidMount() {
    const { isOpen } = this.props
    if (this.modalRoot) {
      this.modalRoot.appendChild(this.el)
    }
    if (isOpen === true) {
      this.handleOpen()
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props
    if (prevProps.isOpen === false && isOpen === true) {
      this.handleOpen()
    }

    if (prevProps.isOpen === true && isOpen === false) {
      this.handleClose()
    }
  }

  componentWillUnmount() {
    const { onClose } = this.props
    if (this.modalRoot) {
      this.modalRoot.removeChild(this.el)
    }
    this.handleClose()
    onClose()
  }

  // Focus the dialog when it opens and prevent the document from scrolling.
  handleOpen = () => {
    const { onClose } = this.props
    if (this.modalWindowRef && this.modalWindowRef.current) {
      this.focusTrap = createFocusTrap(this.modalWindowRef.current, {
        onDeactivate: onClose,
      })
    }

    noScroll.on()
    this.focusTrap.activate()
    if (this.modalWindowContentRef && this.modalWindowContentRef.current) {
      this.modalWindowContentRef.current.focus()
    }
  }

  // Make the document scrollable again when the dialog closes.
  handleClose = () => {
    if (this.focusTrap) {
      this.focusTrap.deactivate()
    }
    // Prevent the dialog from jumping by only enabling scrolling again after the dialog is
    // completely gone.
    setTimeout(() => {
      noScroll.off()
    }, animationLeaveDuration)
  }

  handleBackdropClick = event => {
    // Only close when clicking on backdrop.
    if (this.modalWindowRef && event.target === this.modalWindowRef.current) {
      this.handleClose()
    }
  }

  renderContent = () => {
    const { id, label, children } = this.props
    console.log('render content')
    return (
      // eslint-disable-next-line
      <Backdrop key="modalWindow" ref={this.modalWindowRef} onClick={this.handleBackdropClick}>
        <Content
          id={id}
          ref={this.modalWindowContentRef}
          role="dialog"
          aria-label={label}
          tabIndex="-1"
        >
          {children}
          {this.renderCloseButton()}
        </Content>
      </Backdrop>
    )
  }

  renderCloseButton = () => {
    const { hideClose } = this.props
    if (!hideClose) {
      return (
        <CloseButton type="button" onClick={this.handleClose}>
          <CloseIcon height="24" width="24" />
        </CloseButton>
      )
    }
    return null
  }

  render() {
    const { isOpen } = this.props
    if (isOpen) {
      return ReactDOM.createPortal(this.renderContent(), this.el)
    }
    return null
  }
}

ModalWindowContent.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hideClose: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.node,
}

export default ModalWindowContent
