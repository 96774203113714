import styled, { css } from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'

export const StyledMainMenu = styled.div`
  background-color: #333f4d;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  align-items: stretch;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  box-shadow: inset -1px 0 0 #1f262e;
`

export const Topbar = styled.div`
  height: 60px;
  padding: 10px 14px;
  flex: 0 0 auto;

  ${mediaQueries.mobile`
    padding: 5px;
    height: 50px;
    width: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  `};
`

export const Nav = styled.nav`
  flex-grow: 1;
`

export const Menu = styled.ul`
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
`

export const Item = styled.li`
  margin: 0;
  padding: 0;
  width: 100%;
`
export const ItemLinkIcon = styled.span`
  margin-right: 10px;
  width: 20px;

  svg {
    fill: #d7d9db;
    width: 20px;
    height: 20px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemLink = styled.a`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  min-height: 24px;
  padding: 10px 14px;
  border-left: 0px solid transparent;
  transition: color linear 100ms;
  text-decoration: none;
  color: #d7d9db;
  cursor: pointer;

  &:hover {
    background: #2d3743;
    color: #fff;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: #27303a;
      box-shadow: inset 0 0 0 1px #1f262e;
      color: #fff;

      &:hover {
        background: #27303a;
        box-shadow: inset 0 0 0 1px #1f262e;
        color: #fff;
      }

      ${ItemLinkIcon} {
        svg {
          fill: #fff;
        }
      }
    `};
`

export const ItemLinkLabel = styled.span`
  ${mediaQueries.mobile`
    display: none; 
  `};
`

export const Bottombar = styled.div`
  background-color: $background-color;
  height: 70px;
  width: 100%;
  flex: 0 0 auto;
  padding: 10px 14px;

  ${mediaQueries.mobile`
    height: 50px;
    padding: 0;
    margin-bottom: 100px;
  `};
`

export const ExitLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  line-height: 25px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: none;
      font-size: 30px;

      ${mediaQueries.mobile`
        display: flex;
        justify-content: center;
      `};
    `};

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      display: flex;

      ${mediaQueries.mobile`
        display: none;
      `};
    `};
`

export const LoggedInText = styled.div`
  color: #fff;
  font-size: 12px;
  display: block;
  line-height: 25px;

  ${mediaQueries.mobile`
    display: none;
  `};
`

export const DesktopLogo = styled.span`
  display: block;
  height: 40px;

  svg {
    height: 40px;
    width: 130px;
  }

  ${mediaQueries.mobile`
    display: none;
    height: 30px;
  `};
`
export const MobileLogo = styled.span`
  display: none;
  height: 40px;

  svg {
    height: 40px;
    width: 40px;
  }

  ${mediaQueries.mobile`
    display: block;
    svg {
      height: 30px;
      width: 30px;
    }
  `};
`
