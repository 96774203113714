export const ROLE = {
  USER: 1,
  ARTIST: 2,
  ADMIN: 3,
  CUSTOMER: 4,
}

export const ROLES = [ROLE.USER, ROLE.CUSTOMER, ROLE.ARTIST, ROLE.ADMIN]

const roleText = {
  1: 'Bruker',
  2: 'Artist',
  3: 'Admin',
  4: 'Kunde',
}

// eslint-disable-next-line
export const getRoleText = r => {
  if (roleText[r]) {
    return roleText[r]
  }
  return 'undefined'
}
