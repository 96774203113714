import React from 'react'

import SVGWrapper from './svg-wrapper'

const MergeIcon = props => (
  <SVGWrapper width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M17 20.41L18.41 19 15 15.59 13.59 17 17 20.41zM7.5 8H11v5.59L5.59 19 7 20.41l6-6V8h3.5L12 3.5 7.5 8z" />
  </SVGWrapper>
)

export default MergeIcon
