import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import styled, { ThemeProvider, css } from 'styled-components'
import theming from 'styled-theming'
import { darken } from 'polished'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

import Loader from '../loader'

const backgroundColor = theming('buttonType', {
  primary: gigplanetTheme.color.primary,
  secondary: '#fff',
  outline: '#fff',
  abort: 'red',
  cancel: '#fff',
})

const backgroundHoverColor = theming('buttonType', {
  primary: darken(0.1, gigplanetTheme.color.primary),
  secondary: darken(0.1, '#fff'),
  outline: darken(0.1, '#fff'),
  abort: darken(0.1, 'red'),
  cancel: darken(0.1, '#fff'),
})

const backgroundDisabledColor = theming('buttonType', {
  primary: gigplanetTheme.color.primary,
  secondary: '#fff',
  outline: '#fff',
  abort: 'red',
  cancel: '#fff',
})

const textColor = theming('buttonType', {
  primary: '#fff',
  secondary: gigplanetTheme.color.primary,
  outline: gigplanetTheme.color.textColor,
  abort: '#fff',
  text: gigplanetTheme.color.textColor,
  cancel: gigplanetTheme.color.primary,
})

const textHoverColor = theming('buttonType', {
  primary: '#fff',
  secondary: gigplanetTheme.color.primary,
  outline: gigplanetTheme.color.textColor,
  abort: '#fff',
  text: gigplanetTheme.color.textColor,
  cancel: gigplanetTheme.color.primary,
})

const textDisabledColor = theming('buttonType', {
  primary: '#ccc',
  secondary: gigplanetTheme.color.primary,
  outline: gigplanetTheme.color.textColor,
  abort: '#fff',
  text: gigplanetTheme.color.textColor,
  cancel: gigplanetTheme.color.primary,
})

const borderColor = theming('buttonType', {
  primary: gigplanetTheme.color.primary,
  secondary: gigplanetTheme.color.primary,
  outline: gigplanetTheme.color.primary,
  abort: 'red',
  cancel: gigplanetTheme.color.primary,
})

const borderHoverColor = theming('buttonType', {
  primary: gigplanetTheme.color.primary,
  secondary: gigplanetTheme.color.primary,
  outline: gigplanetTheme.color.primary,
  abort: darken(0.1, 'red'),
  cancel: gigplanetTheme.color.primary,
})

const borderDisabledColor = theming('buttonType', {
  primary: gigplanetTheme.color.primary,
  secondary: gigplanetTheme.color.primary,
  outline: gigplanetTheme.color.primary,
  abort: 'red',
  cancel: gigplanetTheme.color.primary,
})

const ButtonWrapper = ({
  hrefAs,
  href,
  buttonType,
  type,
  children,
  isWide,
  isLoading,
  isUppercase,
  ...rest
}) => {
  if (hrefAs) {
    return (
      <Link href={href} as={hrefAs} passHref>
        <a {...rest}>{children}</a>
      </Link>
    )
  }
  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    )
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} {...rest}>
      {children}
    </button>
  )
}

ButtonWrapper.propTypes = {
  hrefAs: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
}

const StyledInput = styled(ButtonWrapper)`
  flex: 1 0 auto;
  appearance: none;
  background-color: ${gigplanetTheme.color.inputBackground};
  border: 1px solid ${gigplanetTheme.color.inputBorder};
  border-radius: 2px;
  color: ${gigplanetTheme.color.inputText};
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  padding: 8px 10px;
  text-align: left;
  transition: border-color 0.2s ease-in-out;

  ${({ isWide }) =>
    isWide &&
    css`
      width: 100%;
    `};

  &[disabled] {
    background-color: ${gigplanetTheme.color.inputDisabledBackground};
    color: ${gigplanetTheme.color.inputDisabledText};
  }
`

const StyledButton = styled(ButtonWrapper)`
  background: ${backgroundColor};
  border: 1px solid ${borderColor};
  color: ${textColor};
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  line-height: 32px;
  padding: 2px 20px;

  ${({ size }) =>
    size === 'large' &&
    css`
      padding: 20px 50px;
    `};

  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 2px 20px;
      font-size: 14px;
    `};

  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  word-wrap: normal;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  ${({ isUppercase }) =>
    isUppercase &&
    css`
      text-transform: uppercase;
    `};

  &[disabled] {
    background: ${backgroundDisabledColor};
    border: 1px solid ${borderDisabledColor};
    color: ${textDisabledColor};
    cursor: default;
  }

  ${mediaQueries.tabletPortraitDown`
    font-size: 14px;
  `};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background: ${backgroundHoverColor};
        border: 1px solid ${borderHoverColor};
        color: ${textHoverColor};
      }
    `};

  ${({ isWide }) =>
    isWide &&
    css`
      width: 100%;
    `};

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: wait;
    `};
`
const IconAction = styled(ButtonWrapper)`
  color: #000;
`

const Text = styled(ButtonWrapper)`
  border: 0;
  appearance: none;
  padding: 0;
  display: inline-block;
  margin: 0;
  color: ${textColor};
  background: none;
  cursor: pointer;

  ${({ isWide }) =>
    isWide &&
    css`
      width: 100%;
    `};

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: wait;
    `};

  &:hover {
    color: ${textHoverColor};
  }
`

const LoadingWrapper = styled.span`
  display: inline-block;
  opacity: 0;
  width: 0;
  height: 32px;
  transition: all 0.2s;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 1;
      width: 32px;
      margin-left: 10px;
    `};
`
const TextWrapper = styled.span`
  opacity: 1;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  word-wrap: normal;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`

const Button = ({
  type,
  theme,
  href,
  wide,
  disabled,
  loading,
  as,
  size,
  uppercase,
  onClick,
  children,
}) => {
  const loadingElement = (
    <LoadingWrapper isLoading={loading}>
      <Loader white={theme === 'primary'} />
    </LoadingWrapper>
  )
  const childrenElement = <TextWrapper>{children}</TextWrapper>
  // Let's disable this link if we are loading something.
  const handleClick = e => {
    if (loading) {
      e.preventDefault()
    } else if (onClick) {
      onClick(e)
    }
  }

  switch (theme) {
    case 'icon-action':
      return (
        <IconAction
          theme={theme}
          disabled={disabled || loading}
          hrefAs={as}
          href={href}
          type={type}
          onClick={handleClick}
          isWide={wide}
          isLoading={loading}
          isUppercase={uppercase}
        >
          {childrenElement}
          {loadingElement}
        </IconAction>
      )
    case 'input':
      return (
        <StyledInput
          disabled={disabled || loading}
          hrefAs={as}
          href={href}
          type={type}
          onClick={handleClick}
          isWide={wide}
          isLoading={loading}
          size={size}
          isUppercase={uppercase}
        >
          {childrenElement}
        </StyledInput>
      )

    case 'primary':
    case 'secondary':
    case 'abort':
    case 'cancel':
    case 'transparent':
    case 'outline':
      return (
        <ThemeProvider theme={{ buttonType: theme }}>
          <StyledButton
            disabled={disabled || loading}
            hrefAs={as}
            href={href}
            type={type}
            onClick={handleClick}
            isWide={wide}
            isLoading={loading}
            size={size}
            isUppercase={uppercase}
          >
            <React.Fragment>
              {childrenElement}
              {loadingElement}
            </React.Fragment>
          </StyledButton>
        </ThemeProvider>
      )
    case 'text':
      return (
        <ThemeProvider theme={{ buttonType: theme }}>
          <Text
            disabled={disabled || loading}
            hrefAs={as}
            href={href}
            type={type}
            onClick={handleClick}
            isWide={wide}
            isUppercase={uppercase}
          >
            {childrenElement}
          </Text>
        </ThemeProvider>
      )

    default:
      return <div>Please choose theme</div>
  }
}

Button.propTypes = {
  theme: PropTypes.oneOf([
    'icon-action',
    'primary',
    'secondary',
    'abort',
    'cancel',
    'text',
    'transparent',
    'outline',
    'input',
  ]).isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  as: PropTypes.string,
  href: PropTypes.string,
  wide: PropTypes.bool,
  loading: PropTypes.bool,
  uppercase: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'large', 'small']),
}

Button.defaultProps = {
  onClick: null,
  disabled: false,
  wide: false,
  loading: false,
  uppercase: false,
  type: '',
  as: '',
  href: '',
  size: 'normal',
}

export default Button
