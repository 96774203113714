import * as React from 'react'
import PropTypes from 'prop-types'

const GigplanetSymbol = ({ alt, inverted = false }) => (
  <svg
    role="presentation"
    alt={alt}
    width="240"
    height="240"
    viewBox="0 0 240 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={inverted ? 'white' : '#390087'}
      d="M120,8.67A111.33,111.33,0,1,0,231.33,120,111.34,111.34,0,0,0,120,8.67Zm44.5,167h0c0,10.45-7.51,17.75-18.26,17.75S128,186.12,128,175.67V83.16a7.89,7.89,0,0,0-15.78,0v63.61h0c0,10.43-7.52,17.71-18.25,17.71s-18.24-7.28-18.26-17.71h0v0h0V112.06a7.49,7.49,0,0,0-7.89-7.74,7.49,7.49,0,0,0-7.89,7.74v30.63H49.54V112.35c0-10.45,7.51-17.75,18.26-17.75s18.26,7.3,18.26,17.75v34.7c0,4.53,3.24,7.7,7.89,7.7s7.89-3.17,7.89-7.7V83.45c0-10.46,7.51-17.76,18.26-17.76s18.25,7.3,18.25,17.76V176c0,4.53,3.25,7.69,7.89,7.69s7.9-3.16,7.9-7.69V45.2H164.5Z"
    />
  </svg>
)

GigplanetSymbol.propTypes = {
  alt: PropTypes.string,
  inverted: PropTypes.bool,
}

GigplanetSymbol.defaultProps = {
  alt: '',
}

export default GigplanetSymbol
