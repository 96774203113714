import React from 'react'
import PropTypes from 'prop-types'

import { ERROR, SUCCESS, INFO } from '../../../models/SystemNotification'

import { StyledSystemNotificationsItem, Message, Actions, ActionButton } from './style'

const SystemNotificationsItem = ({ id, type, message, onRemove }) => (
  <StyledSystemNotificationsItem
    isError={type === ERROR}
    isSuccess={type === SUCCESS}
    isInfo={type === INFO}
  >
    <Message>{message}</Message>
    <Actions>
      <ActionButton
        type="button"
        onClick={e => {
          e.preventDefault()
          onRemove(id)
        }}
      >
        Lukk
      </ActionButton>
    </Actions>
  </StyledSystemNotificationsItem>
)

SystemNotificationsItem.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default SystemNotificationsItem
