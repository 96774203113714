import styled, { css } from 'styled-components'

export const Container = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  width: 100%;

  ${({ alignLeft }) =>
    alignLeft &&
    css`
      justify-content: flex-start;
    `}
`

export const Spinner = styled.span`
  align-self: center;
  flex: 0 0 auto;
  width: 32px;
  height: 32px;

  ${({ size }) =>
    size === 'medium' &&
    css`
      height: 64px;
      width: 64px;
    `};

  ${({ size }) =>
    size === 'large' &&
    css`
      height: 128px;
      width: 128px;
    `};
`

export const Text = styled.span`
  align-self: center;
  flex: 0 0 auto;
`
