import styled from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const StyledAdminContent = styled.div`
  box-shadow: 0 0 0 1px ${gigplanetTheme.color.border};
  display: flex;
  flex-direction: row;

  ${mediaQueries.tabletLandscapeDown`
    flex-direction: column;
  `};
`

export const Content = styled.div`
  padding: 30px 20px;
  background-color: #fff;
  flex: 1 0 auto;

  ${mediaQueries.mobile`
    padding: 15px;
  `};
`
