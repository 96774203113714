import styled, { css } from 'styled-components'
import { rgba } from 'polished'

export const StyledSystemNotifications = styled.ul`
  list-style: none;
  margin: 0;
  position: fixed;
  right: 10px;
  top: 20px;
  z-index: 101;
`

export const StyledSystemNotificationsItem = styled.li`
  padding: 15px;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 3px;
  margin: 0 0 10px;
  max-width: 300px;
  min-height: 50px;
  min-width: 250px;

  ${({ isError }) =>
    isError &&
    css`
      background: ${rgba('#fce6e2', 0.8)};

      &:hover {
        background: ${rgba('#fce6e2', 1)};
      }
    `};

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      background: ${rgba('#e1faea', 0.8)};

      &:hover {
        background: ${rgba('#e1faea', 1)};
      }
    `};

  ${({ isInfo }) =>
    isInfo &&
    css`
      background: ${rgba('#fff3d9', 0.8)};

      &:hover {
        background: ${rgba('#fff3d9', 1)};
      }
    `};
`

export const Message = styled.p`
  font-size: 12px;
  margin: 0;
`

export const Actions = styled.div`
  padding-top: 10px;
  text-align: right;
`

export const ActionButton = styled.button`
  background: rgba(10, 10, 10, 0.25);
  border: 0;
  cursor: pointer;
  font-size: 11px;
  padding: 5px 10px;
  text-transform: uppercase;

  &:hover {
    background: rgba(10, 10, 10, 0.1);
  }
`
