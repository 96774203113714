import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

// import HelpPopup from '../../../components/HelpPopup'
import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'

const Element = styled.label`
  color: ${gigplanetTheme.color.labelText};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  padding: 0 0 2px;
  text-align: left;
  font-weight: 600;

  ${mediaQueries.mobile`
    font-size: 16px;
  `};

  ${({ isCheckbox }) =>
    isCheckbox &&
    css`
      font-weight: 300;
    `};

  ${({ isRadio }) =>
    isRadio &&
    css`
      font-weight: 300;
    `};

  ${({ isPositionLeft }) =>
    isPositionLeft &&
    css`
      line-height: 20px;
      padding: 8px 10px 8px 0;
    `};

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: default;
    `};

  ${({ isLineThrough }) =>
    isLineThrough &&
    css`
      text-decoration: line-through;
    `};
`

const HelpPopupWrapper = styled.span`
  margin-left: 5px;
`

const GotLabel = styled.span`
  background-color: ${gigplanetTheme.color.primary};
  border-radius: 3px;
  display: inline-block;
  height: 6px;
  width: 6px;
  position: relative;
  top: -3px;
  right: -5px;
`

const Label = ({
  label,
  htmlFor,
  children,
  showValueMarker,
  hide,
  position,
  checkbox,
  radio,
  disabled,
  lineThrough,
  labelHelp,
}) => (
  <Element
    isCheckbox={checkbox}
    isRadio={radio}
    isPositionLeft={position === 'left'}
    isHidden={hide}
    isDisabled={disabled}
    isLineThrough={lineThrough}
    htmlFor={htmlFor}
  >
    {label || children}
    {showValueMarker ? <GotLabel /> : null}
    {labelHelp ? <HelpPopupWrapper>{/* <HelpPopup text={labelHelp} /> */}</HelpPopupWrapper> : null}
  </Element>
)

Label.propTypes = {
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  children: PropTypes.string,
  showValueMarker: PropTypes.bool,
  hide: PropTypes.bool,
  labelHelp: PropTypes.string,
  position: PropTypes.string,
  checkbox: PropTypes.bool,
  radio: PropTypes.bool,
  disabled: PropTypes.bool,
  lineThrough: PropTypes.bool,
}

Label.defaultProps = {
  label: '',
  labelHelp: '',
  htmlFor: '',
  children: '',
  showValueMarker: false,
  hide: false,
  position: 'top',
  checkbox: false,
  radio: false,
  disabled: false,
  lineThrough: false,
}

export default Label
