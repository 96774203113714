import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'

import useCurrentUser from '../../hooks/useCurrentUser'

import getGlobals from '../../helpers/get-globals'
import {
  StyledMainMenu,
  Topbar,
  Bottombar,
  DesktopLogo,
  MobileLogo,
  ExitLink,
  LoggedInText,
  Nav,
  Menu,
  Item,
  ItemLink,
  ItemLinkLabel,
  ItemLinkIcon,
} from './style'
import GigplanetLogo from '../../ui/gigplanet-logo'
import GigplanetSymbol from '../../ui/gigplanet-symbol'
import {
  ExitIcon,
  DashboardIcon,
  MoneyIcon,
  GroupIcon,
  HeadsetIcon,
  StarIcon,
  MusicNoteIcon,
  CheckIcon,
  MapIcon,
} from '../../icons'

const getLink = (as, href, activeUrl, label, renderIcon) => (
  <Item>
    <Link href={href} as={as} passHref>
      <ItemLink isActive={as === activeUrl || (activeUrl.startsWith(as) && as.length > 1)}>
        <ItemLinkIcon>{renderIcon()}</ItemLinkIcon>
        <ItemLinkLabel>{label}</ItemLinkLabel>
      </ItemLink>
    </Link>
  </Item>
)

const MainMenu = () => {
  const currentUser = useCurrentUser()
  const router = useRouter()
  const { asPath } = router

  return (
    <StyledMainMenu>
      <Topbar>
        <a href="/" title="Gå til dashboard">
          <DesktopLogo>
            <GigplanetLogo inverted />
          </DesktopLogo>
          <MobileLogo>
            <GigplanetSymbol inverted />
          </MobileLogo>
        </a>
      </Topbar>
      <Nav>
        <Menu>
          {getLink('/', '/index', asPath, 'Dashboard', () => (
            <DashboardIcon />
          ))}
          {getLink('/tasks', '/tasks/index', asPath, 'Oppgaver', () => (
            <CheckIcon />
          ))}
          {getLink('/gigrequests', '/gigrequests/index', asPath, 'Forespørsler', () => (
            <MoneyIcon />
          ))}
          {getLink('/users', '/users/index', asPath, 'Brukere', () => (
            <GroupIcon />
          ))}
          {getLink('/artists', '/artists/index', asPath, 'Artister', () => (
            <HeadsetIcon />
          ))}
          {getLink('/reviews', '/reviews/index', asPath, 'Anmeldelser', () => (
            <StarIcon />
          ))}
          {getLink(
            '/repertoire/list/artists',
            '/repertoire/list/[type]',
            asPath,
            'Repertoar',
            () => (
              <MusicNoteIcon />
            ),
          )}
          {getLink('/map', '/map/index', asPath, 'Kart', () => (
            <MapIcon />
          ))}
        </Menu>
      </Nav>
      <Bottombar>
        <LoggedInText>Logget inn som: {currentUser.firstName}</LoggedInText>
        <ExitLink href={getGlobals().FRONTEND_SERVER_HOST} isDesktop>
          Avslutt
          <ExitIcon />
        </ExitLink>
        <ExitLink href={getGlobals().FRONTEND_SERVER_HOST} isMobile>
          <ExitIcon />
        </ExitLink>
      </Bottombar>
    </StyledMainMenu>
  )
}

export default MainMenu
