import styled from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'

export const StyledMainLayout = styled.section``

export const NoAccessWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;

  ${mediaQueries.mobile`
    padding: 15px;
  `};
`

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 180px;
  height: 100vh;
  z-index: 100;
  overflow: auto;
  ${mediaQueries.mobile`
    width: 45px;
  `};
`

export const ContentWrapper = styled.div`
  padding-left: 180px;
  overflow: auto;
  display: block;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;

  ${mediaQueries.mobile`
    padding-left: 45px;
  `};
`
