import styled, { css } from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'

export const StyledAdminSidebar = styled.div`
  padding: 30px;
  background: #fbfcfd;
  box-sizing: border-box;
  flex: 1 1 auto;
  overflow: auto;
  padding-left: 0;
  padding-right: 0;
  width: 180px;
  max-width: 180px;
  min-width: 180px;
  box-shadow: inset -1px 0 0 #e3e5e8;

  ${mediaQueries.tabletLandscapeDown`
    padding: 15px;
    background: transparent;
    width: 100%;
    min-width: 100%;
  `};
`

export const DesktopMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  ${mediaQueries.tabletLandscapeDown`
    display: none;
  `};
`
export const MobileMenu = styled.div`
  display: none;

  ${mediaQueries.tabletLandscapeDown`
    display: block;
  `};

  & > * {
    padding: 0;
  }
`

export const MenuItem = styled.li`
  margin: 0;
  padding: 0;
  position: relative;
`

export const MenuLink = styled.a`
  color: #29323d;
  display: block;
  font-size: 12px;
  line-height: 20px;
  padding: 9px 0;
  padding-left: 20px;
  text-decoration: none;

  &:hover {
    background: rgba(41, 50, 61, 0.05);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #737f8c;
      color: #fff;

      &:hover {
        background-color: #737f8c;
        color: #fff;
      }
    `};
`
