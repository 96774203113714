import React from 'react'

import PropTypes from 'prop-types'

import Input from './components/input'
import Textarea from './components/textarea'
import Select from './components/select'
import Checkbox from './components/checkbox'
import Label from './components/label'
import Errormessage from './components/errormessage'
import Radio from './components/radio'
import DatePicker from './components/date-picker'
import Rating from './components/rating'

const Form = ({ onSubmit, children }) => <form onSubmit={onSubmit}>{children}</form>

Form.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.node,
}

Form.defaultProps = {
  onSubmit: null,
  children: null,
}

export { Input, Textarea, Select, Checkbox, Errormessage, Label, Radio, DatePicker, Rating }

export default Form
