/* global document window */

let scrollbarSize = null
let lastScrollTop = null
let originalPosition = null
let originalTop = null
let originalLeft = null
let originalRight = null

const calculateScrollbarSize = () => {
  if (scrollbarSize !== null) return scrollbarSize

  const dummy = document.createElement('div')
  dummy.style.width = '99px'
  dummy.style.height = '99px'
  dummy.style.position = 'absolute'
  dummy.style.top = '-9999px'
  dummy.style.overflow = 'scroll'

  document.documentElement.appendChild(dummy)
  scrollbarSize = dummy.offsetWidth - dummy.clientWidth
  document.documentElement.removeChild(dummy)

  return scrollbarSize
}

const on = () => {
  if (lastScrollTop !== null) return

  lastScrollTop = window.pageYOffset

  originalPosition = document.body.style.position
  originalTop = document.body.style.top
  originalLeft = document.body.style.left
  originalRight = document.body.style.right

  scrollbarSize = calculateScrollbarSize()

  // Unfortunately, setting the body's position to "fixed" makes the page scroll to the top. To
  // work around this we adjust the body's top when noScroll is turned on, and reset the scroll
  // position when it's turned off.
  document.body.style.position = 'fixed'
  document.body.style.top = lastScrollTop ? `${-lastScrollTop}px` : 0
  document.body.style.left = 0
  document.body.style.right = scrollbarSize ? `${scrollbarSize}px` : 0
}

const off = () => {
  if (lastScrollTop === null) return

  document.body.style.position = originalPosition
  document.body.style.top = originalTop
  document.body.style.left = originalLeft
  document.body.style.right = originalRight

  // Reset the old scroll position.
  window.scrollTo(0, lastScrollTop)
  lastScrollTop = null
}

export default {
  on,
  off,
}
