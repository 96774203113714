import React from 'react'
import PropTypes from 'prop-types'

import { StyledHeader, Heading, ActionButton, ButtonText } from './style'

const AdminHeader = ({ children, actions }) => (
  <StyledHeader>
    <Heading>{children}</Heading>
    {actions && actions.length > 0 ? (
      <div className="AdminHeader__actions">
        {actions.map(action => (
          <ActionButton key={action.key} onClick={action.onClick}>
            {action.renderIcon ? action.renderIcon() : null}
            <ButtonText>{action.text}</ButtonText>
          </ActionButton>
        ))}
      </div>
    ) : null}
  </StyledHeader>
)

AdminHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      text: PropTypes.string.isRequired,
      renderIcon: PropTypes.func,
    }),
  ),
}

AdminHeader.defaultProps = {
  actions: [],
}

export default AdminHeader
