import styled, { css } from 'styled-components'

import gigplanetTheme from '../../../../styles/gigplanetTheme'

export const StyledRating = styled.div`
  max-width: 450px;
  min-width: 250px;
  padding-top: 10px;
  position: relative;
  width: 100%;
`

export const Fieldset = styled.fieldset`
  border: 0;
  border-radius: 1px;
  padding: 0;
  margin: 5px 0 0 0;
  display: inline-block;
  direction: rtl;
  /* remove inline-block whitespace */
  font-size: 0;
`

export const Label = styled.label`
  color: ${gigplanetTheme.color.labelText};
  display: block;
  font-size: 18px;
  line-height: 20px;
  padding: 10px 0 0;
`

export const StarLabel = styled.label`
  display: inline-block;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  height: 24px;
  width: 24px;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
`

export const IconWrapper = styled.span`
  position: absolute;
  color: ${gigplanetTheme.color.ratingStarEmpty};

  ${({ isFilled }) =>
    isFilled &&
    css`
      overflow: hidden;
      color: ${gigplanetTheme.color.ratingStarFilled};
    `};
`

export const StarInput = styled.input`
  display: none;
`
