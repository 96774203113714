import styled from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'

export const StyledHeader = styled.header`
  padding: 10px 20px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  background: #e7eef3;
  box-shadow: 0 1px 0 rgba(0, 0, 20, 0.1);

  ${mediaQueries.mobile`
    height: 50px;
  `};
`

export const Heading = styled.h1`
  font-size: 24px;
  color: #29323d;
  font-weight: normal;
  line-height: 32px;
  font-weight: 300;
  margin: 0;


  ${mediaQueries.mobile`
    font-size: 20px;
    line-height: 30px;
  `};
`

export const ActionButton = styled.button`
  padding: 5px 20px;
  background: #fff;
  border: 1px solid #e3e5e8;
  border-radius: 3px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
`

export const ButtonText = styled.span`
  display: inline-block;
  height: 30px;
  line-height: 30px;
  vertical-align: top;
`
