/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Label from './label'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const Container = styled.div`
  max-width: 450px;
  min-width: 200px;
  position: relative;
  width: 100%;
  text-align: left;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      max-width: 100%;
    `};

  ${({ isNarrow }) =>
    isNarrow &&
    css`
      min-width: 100px;
    `};
`

const Element = styled.select`
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M7 10l5 5 5-5z' style='fill: %23c2c2c2' /><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-origin: content-box;
  background-position: right 0 center;
  background-repeat: no-repeat;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  color: #565a5c;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 8px 4px 8px 8px;
  position: relative;
  text-transform: none;
  width: 100%;
  z-index: ${gigplanetTheme.zIndex.formElements};

  &[disabled] {
    background-color: ${gigplanetTheme.color.inputDisabledBackground};
    color: ${gigplanetTheme.color.inputDisabledText};
  }

  ${({ isError }) =>
    isError &&
    `
    border: 1px solid ${gigplanetTheme.color.inputError};
  `};

  ${({ isValueMarker }) =>
    isValueMarker &&
    `
    // border: 1px solid ${gigplanetTheme.color.primary};
  `};
`

const HelptextElement = styled.small`
  color: #82888a;
  font-size: 12px;
  line-height: 15px;
`

const ErrormessageElement = styled.small`
  color: ${gigplanetTheme.color.inputError};
  font-size: 12px;
  line-height: 15px;
`

const Select = ({
  options,
  label,
  hideLabel,
  helpText,
  id,
  value,
  onChange,
  actionLabel,
  disabled,
  autoFocus,
  children,
  showValueMarker,
  labelHelp,
  expanded,
  narrow,
  errorMessage,
  name = ''
}) => {
  if (!options && !children) return null
  let optionsElements = []
  if (options && options.length > 0) {
    optionsElements = options.map(opt => (
      <option key={opt.value} value={opt.value} disabled={opt.disabled}>
        {opt.name}
      </option>
    ))
  }

  const actionOption = actionLabel ? <option value="">{actionLabel}</option> : null
  const select = (
    <Element
      isError={!!errorMessage}
      isValueMarker={showValueMarker}
      value={value}
      id={id}
      name={name}
      onChange={onChange}
      disabled={disabled}
      autoFocus={autoFocus}
    >
      {actionOption}
      {children || optionsElements}
    </Element>
  )

  const tmpHelpText = <HelptextElement>{helpText}</HelptextElement>

  const tmpErrorMessage = <ErrormessageElement>{errorMessage}</ErrormessageElement>

  let helpTextElement = null
  if (errorMessage) {
    helpTextElement = tmpErrorMessage
  } else if (helpText) {
    helpTextElement = tmpHelpText
  }

  if (label || helpText) {
    return (
      <Container isExpanded={expanded} isNarrow={narrow}>
        <Label
          htmlFor={id}
          showValueMarker={showValueMarker}
          hide={hideLabel}
          labelHelp={labelHelp}
        >
          {label}
        </Label>
        {select}
        {helpTextElement}
      </Container>
    )
  }
  return (
    <Container isExpanded={expanded} isNarrow={narrow}>
      {select}
    </Container>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  actionLabel: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  showValueMarker: PropTypes.bool,
  labelHelp: PropTypes.string,
  expanded: PropTypes.bool,
  narrow: PropTypes.bool,
  errorMessage: PropTypes.string,
}

Select.defaultProps = {
  options: [],
  label: '',
  labelHelp: '',
  disabled: false,
  autoFocus: false,
  hideLabel: false,
  showValueMarker: false,
  onChange: null,
  children: null,
  actionLabel: '',
  value: '',
  helpText: '',
  expanded: false,
  narrow: false,
  errorMessage: '',
}

export default Select
