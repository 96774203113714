import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

import ReactDayPickerCSS from './vendors/react-day-picker'

const GlobalStyle = createGlobalStyle`
 ${styledNormalize}

 ${ReactDayPickerCSS}
  
  html {
    box-sizing: border-box;
    height: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    height: 100%;
    color: black;
    background-color: #ebedef;
    font-family: 'Open Sans', Arial, sans-serif;
  }
`

export default GlobalStyle
